import React from 'react';
import Box from 'common/src/components/Box';
import Container from 'common/src/components/UI/Container';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import './ctasection.css';

const Ctasection = ({
  name,
  button,
  box_size,
  box_size2,
  cta_size_container,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      hostingJson {
        CTA_Section {
          Home {
            title
            action
          }
          RAPBot {
            title
            action
          }
          RAPFlow {
            title
            action
          }
          GettingStarted {
            title
            action
          }
          FormRecognition {
            title
            action
          }
          IntelligentEmail {
            title
            action
          }
          CheckRecognition {
            title
            action
          }
          QuoteProcessing {
            title
            action
          }
          CaseDeflection {
            title
            action
          }
          InvoiceProcessing {
            title
            action
          }
          LeaseAbstraction {
            title
            action
          }
          IDVerification {
            title
            action
          }
          BuildYourOwn {
            title
            action
          }
          WorkflowAutomation {
            title
            action
          }
          IT {
            title
            action
          }
          CustomerService {
            title
            action
          }
          Finance {
            title
            action
          }
          Operations {
            title
            action
          }
          BFSI {
            title
            action
          }
          Logistics {
            title
            action
          }
          Healthcare {
            title
            action
          }
          RealEstate {
            title
            action
          }
          CrossIndustry {
            title
            action
          }
          Hospitality {
            title
            action
          }
          SharedServices {
            title
            action
          }
          CustomerServiceAutomation {
            title
            action
          }
        }
      }
    }
  `);
  return (
    <>
      <Container fullWidth noGutter className="cta_size_container">
        {/* cta auto */}
        {Data.hostingJson.CTA_Section[name].map((item, index) => (
          <Box key={`cta-${index}`} className="Cta_container">
            <Box {...box_size}>
              <p>{item.title}</p>
            </Box>
            <Box {...box_size2}>
              <Link to="/bookademo" className="btn btn_tertiary">
                <span className="btn-text">{item.action}</span>
              </Link>
            </Box>
          </Box>
        ))}
      </Container>
    </>
  );
};

Ctasection.propTypes = {
  button: PropTypes.object,
  box_size: PropTypes.object,
  box_size2: PropTypes.object,
};
Ctasection.defaultProps = {
  button: {
    type: 'button',
    fontSize: ['18px', '20px', '20px', '20px', '20px'],
    fontWeight: '600',
    color: '#fff',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
    height: `{5}`,
  },
  box_size: {
    width: [1, 1, 0.6, 0.7],
  },
  box_size2: {
    width: [1, 1, 0.4, 0.3],
  },
};

export default Ctasection;
